/*
 *
 * Application Main
 *
 */
!(function(APP) {
    'use strict';


    var breakpoint = 768;

    $.extend(true, APP, {
        breakpoint: breakpoint,
        init: {
            hesaplamalar: function () {

                var details = $('.tbl-container .details'),
                    yogunluk = 1.24;

                var input = details.find('input');

                input.eq(4).val(yogunluk).attr('readonly', true);
                input.eq(9).val(yogunluk).attr('readonly', true);

                let resultinpf = input.eq(5);
                let metraj = input.eq(10);

                details.on('keyup', function() {
                    /* first form variables*/
                    let enf = input.eq(0).val();
                    let uzunlukf = input.eq(1).val();
                    let masuraAgf = input.eq(2).val();
                    let bosMasuradf = input.eq(3).val();

                    if (enf.length > 0 && uzunlukf.length > 0 && masuraAgf.length > 0 && bosMasuradf.length > 0) {
                        let enf1 = parseFloat(enf).toFixed(2);
                        let uzunlukf1 = parseFloat(uzunlukf).toFixed(2);
                        let masuraAgf1 = parseFloat(masuraAgf).toFixed(2);
                        let bosMasuradf1 = parseFloat(bosMasuradf).toFixed(2);

                        var result = (masuraAgf1 - bosMasuradf1) * 1000 / enf1 / uzunlukf1 / yogunluk;

                        if (Number(result) < 0) {
                            result = '0';
                        }
                        var result1 = parseFloat(result).toFixed(2).toString() + ' mikron';

                        resultinpf.val(result1);
                    }

                    /* second form variables*/
                    let agirlik = input.eq(6).val();
                    let ent = input.eq(7).val();
                    let kalinlik = input.eq(8).val();

                    if (agirlik.length > 0 && ent.length > 0 && kalinlik.length > 0) {
                        let agirlik1 = parseFloat(agirlik).toFixed(2);
                        let ent1 = parseFloat(ent).toFixed(2);
                        let kalinlik1 = parseFloat(kalinlik).toFixed(2);

                        var metrajresult = agirlik1 / ent1 / yogunluk / (kalinlik1 / 1000);

                        if (Number(metrajresult) < 0) {
                            metrajresult = '0';
                        }
                        var metraj1 = parseFloat(metrajresult).toFixed(2).toString() + ' metre';

                        metraj.val(metraj1);
                    }

                });
            },
            form: function() {
                console.log('works');
                var tel = $('.phone');

                if (tel) { tel.inputmask(); }
            },
            nextCollapse: function() {

                $('body').on('click.collapse-next.data-api', '[data-toggle=collapse-next]', function () {
                    var $target = $(this).next();

                    $target.collapse('toggle');
                });
            }
        },

        INIT: function(options) {
            // APP init

            options = options || {};

            var fn;

            for (var i in this.init) {
                if ( Object.prototype.hasOwnProperty.call(this.init, i) && i.charAt(0) !== '_' && typeof(fn = this.init[i]) === 'function' ) {
                    fn.call(this, options && options[i] || options);
                }
            }

            return this;
        }
    });

})(window.APP = window.APP || {});

$(function() {
    'use strict';

    APP.browser = (function() {

        var is = APP.Helper.is,
            val, tmp,
            userAgent = APP.sanitizeXss(navigator.userAgent);

        var browser = {
            mobile: !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(userAgent),
            ios: !!/iPhone|iPad|iPod/i.test(userAgent),
            ie: (tmp = userAgent.toLowerCase().match(/MSIE ([^;]+)|rv:(11)\.[0-9]+/i)) ? parseInt(tmp[1] || tmp[2], 10) : false,
            edge: (tmp = userAgent.indexOf('Edge/')) > 0 ? parseInt(userAgent.substring(tmp + 5, userAgent.indexOf('.', tmp)), 10) : false,
            bp: function() {
                return $(window).width() < APP.breakpoint;
            }
        };

        var $el = $('html'); // document.documentElement

        for (var k in browser ) {
            if ( Object.prototype.hasOwnProperty.call(browser, k) ) {
                val = browser[k];

                if ( val && !is.function(val) ) {
                    $el.addClass(k);
                    if ( !is.boolean(val) ) {
                        $el.addClass(k + val);
                    }
                }
            }
        }

        APP.browser = browser;

        return browser;
    }());

    APP.scriptPath = APP.Helper.getScriptPath(['app.js', 'app.min.js', 'main.js']);

    APP.Helper.loadScript(APP.scriptPath + 'config.js', {
        success: function() {
            APP.INIT(CONFIG);
        },
        failed: function() {
            APP.INIT();
        }
    });
});
